/**
 * @file Anything that can run on every page.
 */

/**
 * Global functions.
 *
 * Runs component js and any global tasks.
 */
const vGlobal = () => {
	import('../components/nav/nav').then(({ default: vNav }) => { vNav(); });
	if (document.querySelector('.v-hero--hp')) import('../components/hp-hero/hp-hero').then(({ default: vHpHero }) => { vHpHero(); });
	if (document.querySelector('.v-alert')) import('../components/alert/alert').then(({ default: vAlert }) => { vAlert(); });
	if (document.querySelector('.keen-slider')) import('../components/slider/slider').then(({ default: vSlider }) => { vSlider(); });

	// const selects = document.querySelectorAll('select');
	// [...selects].forEach((select) => {
	// 	const selectParent = select.parentNode;
	// 	if (selectParent.classList.contains('v-form__select')) {
	// 		return;
	// 	}

	// 	const wrapper = document.createElement('span');
	// 	const arrow   = document.createElement('span');
	// 	wrapper.classList.add('v-form__select');
	// 	arrow.classList.add('v-form__select-arrow');
	// 	selectParent.insertBefore(wrapper, select);
	// 	wrapper.appendChild(select);
	// 	wrapper.appendChild(arrow);
	// 	select.classList.add('v-form__select-field');
	// });

	/**
	 * Global Window Load.
	 *
	 * Runs polyfills on page load.
	 */
	async function onWindowLoad() {
		const body = document.querySelector('body');
		if (!body.classList.contains('admin-bar')) {
			const { listen } = await import('quicklink');

			listen({
				ignores: [
					(url) => {
						const cleanUrl = url.replace(/#.*/, '');
						return cleanUrl === window.location.href;
					},
					/.*\/wp-admin\/.*/,
				],
			});
		}
	}

	if (document.readyState === 'complete') {
		onWindowLoad();
	} else {
		window.addEventListener('load', onWindowLoad);
	}
};

if (document.readyState !== 'loading') {
	vGlobal();
} else {
	document.addEventListener('DOMContentLoaded', vGlobal);
}
